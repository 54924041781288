import { useState, CSSProperties } from "react";
import BeatLoader from "react-spinners/BeatLoader";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
  backgroundColor: "transparent",
};

export default () => {
  return (
    <div className="bg-transparent">
      <BeatLoader css={override} />
    </div>
  );
};
