// Standard imports
import { BigNumber, ethers, utils } from "ethers";
import contractAddresses from "../assets/contractAddresses.json";

import RouletteGame from "../assets/RouletteGame.json";
import VRFCoordinatorMock from "../assets/VRFCoordinatorV2Mock.json";
import RandomNum from "../assets/Mock.json";
import Forwarder from "../assets/MinimalForwarder.json"
import { getNetwork } from "../api/networks";
import { signMetaTxRequest } from "../api/signer";

// Convert date to POSIX timestamp
export const convertDateToTimestamp = (date) => {
  return Math.round(new Date(date).getTime() / 1000);
};

export const luckyPhraseToInt = (phrase) => {
  if (phrase === "") return 0;

  const hash = utils.keccak256(utils.toUtf8Bytes(phrase));
  const slicedHash = hash.slice(0, 6);

  // Convert Hex to Int
  const int = parseInt(slicedHash);

  return int;
};

//Get the contract object
export const getRouletteContract = async () => {
  const provider = new ethers.providers.JsonRpcProvider()
  const jsonAbi = RouletteGame.abi;
  const rouletteContractAddress = contractAddresses["localhost"]["roulette"];
  const rouletteContract = new ethers.Contract(
    rouletteContractAddress,
    jsonAbi,
    provider
  );
  return rouletteContract
}

export const getVRFCoordinatorContract = async () => {
  const provider = new ethers.providers.JsonRpcProvider()
  const vrfCoordinatorJsonAbi = VRFCoordinatorMock.abi;
  const vrfCoordinatorContractAddress = contractAddresses["localhost"]["VRFCoordinator"];
  const vrfCoordinatorContract = new ethers.Contract(
    vrfCoordinatorContractAddress,
    vrfCoordinatorJsonAbi,
    provider
  );
  return vrfCoordinatorContract
}

export const getRandomNumContract = async () => {
  const provider = new ethers.providers.JsonRpcProvider()
  const randomNumJsonAbi = RandomNum.abi;
  const randomNumContractAddress = contractAddresses["localhost"]["randomNum"];
  const randomNumContract = new ethers.Contract(
    randomNumContractAddress,
    randomNumJsonAbi,
    provider
  );
  return randomNumContract
}

export const getForwarderContract = async () => {
  const {rpcUrl, networkName} = getNetwork()
  const provider = new ethers.providers.JsonRpcProvider(rpcUrl)
  const forwarderJsonAbi = Forwarder.abi;
  const forwarderContractAddress = contractAddresses[networkName]["forwarder"];

  const forwarderContract = new ethers.Contract(
    forwarderContractAddress,
    forwarderJsonAbi,
    provider
  )

  return forwarderContract;
}

//Get the current signer from metamask
const getSigner = () => {
  return new ethers.providers.Web3Provider(window.ethereum).getSigner();
}

//Admin only methods
export const addCompetition = async (rouletteContract, {
  startBal,
  regDuration,
  compDuration,
}) => {
  const signer = getSigner()

  const startingBal = Number(startBal);
  const registrationDuration = Number(regDuration); //in seconds
  const competitionDuration = Number(compDuration); //in seconds

  const unsignedTx = await rouletteContract.connect(signer).populateTransaction.addCompetition(
    startingBal,
    registrationDuration,
    competitionDuration
  );

  try {
    const txResponse = await signer.sendTransaction(unsignedTx);
    const txReceipt = await txResponse.wait();
    return txReceipt
  } catch (err) {
    console.log(err)
  }
};

//
export const enableRegistration = async (rouletteContract, { competitionId }) => {
  const signer = getSigner()

  const unsignedTx =
    await rouletteContract.populateTransaction.enableRegistration(
      competitionId
    );

  try {
    const txResponse = await signer.sendTransaction(unsignedTx);
    const txReceipt = await txResponse.wait();
    return txReceipt
  } catch (err) {
    console.log(err)
  }
};

export const extendRegistration = async (rouletteContract, { competitionId, newEndTime }) => {
  const signer = getSigner()

  console.log('Trying to extend registration for competitionId', competitionId, 'by ', newEndTime)
  const unsignedTx =
    await rouletteContract.populateTransaction.extendRegistration(
      competitionId,
      newEndTime
    );
  try {
    const txResponse = await signer.sendTransaction(unsignedTx);
    const txReceipt = await txResponse.wait();
    return txReceipt
  } catch (err) {
    console.log(err)
  }
};

export const extendCompetition = async (rouletteContract, { competitionId, newEndTime }) => {
  const signer = getSigner()

  const unsignedTx =
    await rouletteContract.populateTransaction.extendCompetition(
      competitionId,
      newEndTime
    );

  try {
    const txResponse = await signer.sendTransaction(unsignedTx);
    const txReceipt = await txResponse.wait();
    return txReceipt
  } catch (err) {
    console.log(err)
  }
};

export const registerPlayer = async (rouletteContract, { competitionId }) => {

  const signer = getSigner()

  const unsignedTx =
    await rouletteContract.populateTransaction.registerPlayer(competitionId);

  try {
    const txResponse = await signer.sendTransaction(unsignedTx);
    const txReceipt = await txResponse.wait();
    return txReceipt
  } catch (err) {
    console.log(err.reason);
  }
};

export const startCompetition = async (rouletteContract, { competitionId }) => {
  const signer = getSigner()

  const unsignedTx =
    await rouletteContract.populateTransaction.startCompetition(competitionId);

  try {
    const txResponse = await signer.sendTransaction(unsignedTx);
    const txReceipt = await txResponse.wait();
    return txReceipt
  } catch (err) {
    console.log(err)
  }
};

export const endCompetition = async (rouletteContract, { competitionId }) => {
  const signer = getSigner()
  const unsignedTx =
    await rouletteContract.populateTransaction.endCompetition(competitionId);

  try {
    const txResponse = await signer.sendTransaction(unsignedTx);
    const txReceipt = await txResponse.wait();
    return txReceipt
  } catch (err) {
    console.log(err)
  }
};

export const placeBet = async (rouletteContract, { competitionId, userSeed, betNum, betAmt }) => {
  const signer = getSigner()

  const unsignedTx = await rouletteContract.populateTransaction.placeBet(
    competitionId,
    userSeed,
    betNum,
    betAmt
  );

  try {
    const txResponse = await signer.sendTransaction(unsignedTx);
    const txReceipt = await txResponse.wait();
    return txReceipt
  } catch (err) {
    console.log(err)
  }

};

export const placeFullBet = async (rouletteContract, { competitionId, userSeed, bets }) => {
  console.log('Placing full bet at', rouletteContract.address, 'with competitionId', competitionId, 'userSeed', userSeed, 'and bets', bets)
  const signer = getSigner()

  const unsignedTx = await rouletteContract.populateTransaction.placeFullBet(
    competitionId,
    userSeed,
    bets
  );

  try {
    const txResponse = await signer.sendTransaction(unsignedTx);
    // console.log('Full Bet Response', txResponse)
    const txReceipt = await txResponse.wait();
    // console.log('Full Bet Receipt', txReceipt)
    return txReceipt
  } catch (err) {
    console.log(err)
  }

};

export const placeFullBetViaRelay = async (rouletteContract, { competitionId, userSeed, bets }) => {
  console.log('Placing full bet with competitionId via relay ', competitionId, 'userSeed', userSeed, 'and bets', bets)
  const signer = getSigner()

  const url = process.env.REACT_APP_OZ_DEFENDER_WEBOOK_URL;
  if (!url) throw new Error(`Missing relayer url`);

  const forwarder = await getForwarderContract();
  const from = await signer.getAddress();
  const data = rouletteContract.interface.encodeFunctionData('placeFullBet', [competitionId, userSeed, bets]);
  const to = rouletteContract.address;

  console.log(forwarder)
  const request = await signMetaTxRequest(signer.provider, forwarder, { to, from, data });

  const res = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(request),
    headers: { 'Content-Type': 'application/json' },
  });

  return res

};

export const registerPlayerViaRelay = async (rouletteContract, { competitionId }) => {

  const signer = getSigner()

  const url = process.env.REACT_APP_OZ_DEFENDER_WEBOOK_URL;
  if (!url) throw new Error(`Missing relayer url`);

  const forwarder = await getForwarderContract();
  const from = await signer.getAddress();
  const data = rouletteContract.interface.encodeFunctionData('registerPlayer', [competitionId]);
  const to = rouletteContract.address;

  console.log(forwarder)
  const request = await signMetaTxRequest(signer.provider, forwarder, { to, from, data });

  const res = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(request),
    headers: { 'Content-Type': 'application/json' },
  });

  return res

};



// Read only methods

export const getTournamentDetails = async (rouletteContract, { tournamentId }) => {

  console.log(`Tournament Id: ${tournamentId}`)
  const tournamentDetails = await rouletteContract.getCompetitionInfo(tournamentId);
  console.log(tournamentDetails)

  const startOutBalance = tournamentDetails.startoutBalance.toString();
  const registrationDuration = tournamentDetails.regDuration.toString();
  const competitionDuration = tournamentDetails.compDuration.toString();
  const registrationStartTime = tournamentDetails.regStartTime.toString();
  const competitionStartTime = tournamentDetails.compStartTime.toString();
  const players = tournamentDetails.players;

  const registrationStart = registrationStartTime !== "0";
  const competitionStart = competitionStartTime !== "0";

  var ts = Math.round(Date.now() / 1000);
  const remainingRegistrationTime = registrationStart
    ? BigNumber.from(registrationStartTime)
      .add(BigNumber.from(registrationDuration))
      .sub(BigNumber.from(ts))
      .toNumber()
    : 0;
  const remainingCompetitionTime = competitionStart
    ? BigNumber.from(competitionStartTime)
      .add(BigNumber.from(competitionDuration))
      .sub(BigNumber.from(ts))
      .toNumber()
    : 0;

  const registrationOngoing =
    BigNumber.from(ts).gte(BigNumber.from(registrationStartTime)) &&
    BigNumber.from(ts).lte(
      BigNumber.from(registrationStartTime).add(
        BigNumber.from(registrationDuration)
      )
    );

  const competitionOngoing =
    BigNumber.from(ts).gte(BigNumber.from(competitionStartTime)) &&
    BigNumber.from(ts).lte(
      BigNumber.from(competitionStartTime).add(
        BigNumber.from(competitionDuration)
      )
    );

  const tournamentInfo = {
    tournamentId,
    players,
    startOutBalance,
    registrationStart,
    competitionStart,
    registrationOngoing,
    competitionOngoing,
    remainingRegistrationTime,
    remainingCompetitionTime,
  };
  return tournamentInfo
}

export const getTournamentPlayerDetails = async (rouletteContract, { tournamentId, playerAddress }) => {
  let player = await rouletteContract.getPlayerDetails(tournamentId, playerAddress)
  const competitionId = player.compId.toString()
  const registered = player.registered
  const balance = player.balance.toString()
  const winCount = player.winCount.toString()

  const playerInfo = {
    playerAddress,
    competitionId,
    registered,
    balance,
    winCount
  }

  return playerInfo
}

export const getAllPlayersOfTournamentWithDetails = async (rouletteContract, { tournamentId }) => {
  let tournamentFullInfo = await getTournamentDetails(rouletteContract, { tournamentId })
  let players = tournamentFullInfo.players
  console.log(players)
  const playersDetails = []
  for (let i = 0; i < players.length; i++) {
    const playerAddress = players[i]
    let playerInfo = await getTournamentPlayerDetails(rouletteContract, { tournamentId, playerAddress })
    playersDetails.push(playerInfo)
  }
  console.log(playersDetails)
  return playersDetails;
}

export const getTournamentFullDetails = async (rouletteContract, { tournamentId }) => {
  let tournamentFullInfo = await getTournamentDetails(rouletteContract, { tournamentId })
  return tournamentFullInfo

};


export const getAllTournaments = async (rouletteContract) => {

  console.log("Getting all tournaments")
  const totalTournaments = await rouletteContract.totalCompetitions();
  console.log(totalTournaments)
  const allTournaments = [];
  for (let i = 0; i < totalTournaments; i++) {
    const tournamentId = i
    const tournamentInfo = await getTournamentDetails(rouletteContract, { tournamentId });
    allTournaments.push(tournamentInfo);
  }
  return allTournaments;
};

export const getCompetitionWinners = async (rouletteContract, { competitionId }) => {
  const winners = await rouletteContract.getWinners(competitionId);
  console.log(winners)
  return winners;
};

export const getAdminAddress = async (rouletteContract) => {
  // a web3provider wraps a standard web3 provider, which is
  // what metamask injects as window.ethereum into each page
  //TODO: change naming: Not admin but organizer role
  const adminAddress = await rouletteContract.hasRole();
  console.log(adminAddress)
  return adminAddress
};

export const checkIfOrganizer = async (rouletteContract, walletAddress) => {
  // a web3provider wraps a standard web3 provider, which is
  // what metamask injects as window.ethereum into each page
  //TODO: change naming: Not admin but organizer role
  const ORGANIZER_ROLE= utils.keccak256(utils.toUtf8Bytes("ORGANIZER_ROLE")) 
  const isOrganizer = await rouletteContract.hasRole(ORGANIZER_ROLE, walletAddress);
  return isOrganizer;
};
