import { BigNumber, ethers } from "ethers";
import RouletteGame from "../assets/RouletteGame.json";
import contractAddresses from "../assets/contractAddresses.json";

//Get the contract object
const getContract = async () => {
	const provider = new ethers.providers.JsonRpcProvider()
  const jsonAbi = RouletteGame.abi;
  const rouletteContractAddress = contractAddresses["roulette"];
  const rouletteContract = new ethers.Contract(
    rouletteContractAddress,
    jsonAbi,
    provider
  );
	return rouletteContract
}

//Admin only methods
export const addCompetition = async ({
  startBal,
  regDuration,
  compDuration,
}) => {
	const rouletteContract = await getContract()
  const signer = new ethers.providers.Web3Provider(window.ethereum).getSigner();

  const startingBal = Number(startBal);
  const registrationDuration = Number(regDuration); //in seconds
  const competitionDuration = Number(compDuration); //in seconds

  const unsignedTx = await rouletteContract.connect(signer).populateTransaction.addCompetition(
    startingBal,
    registrationDuration,
    competitionDuration
  );
  const txResponse = await signer.sendTransaction(unsignedTx);
  const txReceipt = await txResponse.wait();
  return txReceipt
};

// Read only methods
export const getAllTournaments = async () => {
	const rouletteContract = await getContract()

  const totalTournaments = await rouletteContract.totalCompetitions();
  const allTournaments = [];
  for (let i = 0; i < totalTournaments; i++) {
    const tournamentDetails = await rouletteContract.competitionInfo(i);

    const startOutBalance = tournamentDetails.startoutBalance.toString();
    const registrationDuration = tournamentDetails.regDuration.toString();
    const competitionDuration = tournamentDetails.compDuration.toString();
    const registrationStartTime = tournamentDetails.regStartTime.toString();
    const competitionStartTime = tournamentDetails.compStartTime.toString();

    const registrationStart = registrationStartTime !== "0";
    const competitionStart = competitionStartTime !== "0";

    var ts = Math.round(Date.now() / 1000);
    const remainingRegistrationTime = registrationStart
      ? BigNumber.from(registrationStartTime)
          .add(BigNumber.from(registrationDuration))
          .sub(BigNumber.from(ts))
          .toNumber()
      : 0;
    const remainingCompetitionTime = competitionStart
      ? BigNumber.from(competitionStartTime)
          .add(BigNumber.from(competitionDuration))
          .sub(BigNumber.from(ts))
          .toNumber()
      : 0;

    const registrationOngoing =
      BigNumber.from(ts).gte(BigNumber.from(registrationStartTime)) &&
      BigNumber.from(ts).lte(
        BigNumber.from(registrationStartTime).add(
          BigNumber.from(registrationDuration)
        )
      );

    const competitionOngoing =
      BigNumber.from(ts).gte(BigNumber.from(competitionStartTime)) &&
      BigNumber.from(ts).lte(
        BigNumber.from(competitionStartTime).add(
          BigNumber.from(competitionDuration)
        )
      );

    const tournamentInfo = {
      tournamentId: i,
      startOutBalance,
      registrationStart,
      competitionStart,
      registrationOngoing,
      competitionOngoing,
      remainingRegistrationTime,
      remainingCompetitionTime,
    };

    allTournaments.push(tournamentInfo);
  }
  console.log(allTournaments);
  return allTournaments;
};

//
export const enableRegistration = async (id) => {
	const rouletteContract = await getContract()
  const signer = new ethers.providers.Web3Provider(window.ethereum).getSigner();

  const competitionId = id;
  const unsignedTx =
    await rouletteContract.populateTransaction.enableRegistration(
      competitionId
    );
  console.log(unsignedTx);

  const txResponse = await signer.sendTransaction(unsignedTx);
  const txReceipt = await txResponse.wait();
  console.log(txReceipt);
};

export const extendRegistration = async (id, newEndTime) => {
	const rouletteContract = await getContract()
  const signer = new ethers.providers.Web3Provider(window.ethereum).getSigner();

  const competitionId = id;

  console.log(newEndTime, competitionId);
  const unsignedTx =
    await rouletteContract.populateTransaction.extendRegistration(
      competitionId,
      newEndTime
    );
  console.log(unsignedTx);
  const txResponse = await signer.sendTransaction(unsignedTx);
  const txReceipt = await txResponse.wait();
	return txReceipt
};

export const extendCompetition = async (id, newEndTime) => {
	const rouletteContract = await getContract()
  const signer = new ethers.providers.Web3Provider(window.ethereum).getSigner();

  const competitionId = id;

  console.log(newEndTime, competitionId);
  const unsignedTx =
    await rouletteContract.populateTransaction.extendCompetition(
      competitionId,
      newEndTime
    );
  console.log(unsignedTx);

  const txResponse = await signer.sendTransaction(unsignedTx);
  const txReceipt = await txResponse.wait();
  console.log(txReceipt);
};

export const registerPlayer = async (id) => {

	const rouletteContract = await getContract()
  const signer = new ethers.providers.Web3Provider(window.ethereum).getSigner();

  const competitionId = id;
  try {
    const unsignedTx =
      await rouletteContract.populateTransaction.registerPlayer(competitionId);
    console.log(unsignedTx);
    const txResponse = await signer.sendTransaction(unsignedTx);
    const txReceipt = await txResponse.wait();
    console.log(txReceipt);
		return txReceipt
  } catch (e) {
    console.log(e.reason);
  }
};

// Convert date to POSIX timestamp
export const convertDateToTimestamp = (date) => {
  return Math.round(new Date(date).getTime() / 1000);
};

export const startCompetition = async (competitionId) => {
	const rouletteContract = await getContract()
  const signer = new ethers.providers.Web3Provider(window.ethereum).getSigner();

  const unsignedTx =
    await rouletteContract.populateTransaction.startCompetition(competitionId);

  const txResponse = await signer.sendTransaction(unsignedTx);
  const txReceipt = await txResponse.wait();
  console.log(txReceipt);
	return txReceipt
};

export const endCompetition = async (competitionId) => {
	const rouletteContract = await getContract()
  const signer = new ethers.providers.Web3Provider(window.ethereum).getSigner();
  const unsignedTx =
    await rouletteContract.populateTransaction.endCompetition(competitionId);
  console.log(unsignedTx);

  const txResponse = await signer.sendTransaction(unsignedTx);
  const txReceipt = await txResponse.wait();
  console.log(txReceipt);
};

export const getCompetitionWinners = async (competitionId) => {
	const rouletteContract = await getContract()
  const winners = await rouletteContract.getWinners(competitionId);
  console.log(winners)
  return winners;
};



export const getAdminAndAccount = async (competitionId) => {
  // A Web3Provider wraps a standard Web3 provider, which is
  // what MetaMask injects as window.ethereum into each page
  const signer = new ethers.providers.Web3Provider(window.ethereum).getSigner();

  const jsonAbi = RouletteGame.abi;
  const rouletteContractAddress = contractAddresses["multiRoulette"];
  const rouletteContract = new ethers.Contract(
    rouletteContractAddress,
    jsonAbi,
    signer
  );

  const adminAddress = await rouletteContract.owner();
  console.log(`Admin: ${adminAddress}`);

  const accountAddress = await signer.getAddress()
  console.log(`Selected Account: ${accountAddress}`);

  return {adminAddress, accountAddress}

};

