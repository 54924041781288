import { useState, useEffect } from "react";
import Modal from "./Modal/Modal";
import { getAllTournaments, } from "../utils";
import Spinner from "./Spinner";

import { useContracts } from "../context/ContractsContext";
import Competition from "./Competition";
import ExtendRegistration from "./Modal/ExtendRegistration";
import ExtendCompetition from "./Modal/ExtendCompetition";

const AllCompetitions = (props) => {
  const [isBuffering, setIsBuffering] = useState(false);
  const [isRegistrationModalOpen, setIsRegistrationModalOpen] = useState(false);
  const [isCompetitionModalOpen, setIsCompetitionModalOpen] = useState(false);
  const [extendThisIdReg, setExtendThisIdReg] = useState(null);
  const [extendThisIdComp, setExtendThisIdComp] = useState(null);
  const [isExtendRegSubmitting, setIsExtendRegSubmitting] = useState(false);
  const [isExtendCompSubmitting, setIsExtendCompSubmitting] = useState(false);

  const { refreshNow, setRefreshNow } = props;

  const [allTournaments, setAllTournaments] = useState([]);
  const { rouletteContract } = useContracts();

  const refreshTournaments = () => {
    getAllTournaments(rouletteContract).then((data) => setAllTournaments(data));
    // .catch((err) => {
    //   alert("Error: Could not retrieve tournaments");
    // });
  };

  useEffect(() => {
    refreshTournaments();
  }, [isCompetitionModalOpen, isRegistrationModalOpen]);

  if (refreshNow) {
    refreshTournaments();
    setRefreshNow(false);
  }

  console.log(allTournaments);

  return (
    <div>
      {isBuffering && (
        <div className="fixed top-0 left-0 z-50 bg-black bg-opacity-50 h-full w-full flex justify-center items-center">
          <Spinner />
        </div>
      )}
      {isRegistrationModalOpen && (
        <Modal
          isSubmitting={isExtendRegSubmitting}
          setIsOpen={setIsRegistrationModalOpen}
        >
          <ExtendRegistration
            setIsRegistrationModalOpen={setIsRegistrationModalOpen}
            extendThisIdReg={extendThisIdReg}
            setIsExtendRegSubmitting={setIsExtendRegSubmitting}
          />
        </Modal>
      )}
      {isCompetitionModalOpen && (
        <Modal
          isSubmitting={isExtendCompSubmitting}
          setIsOpen={setIsCompetitionModalOpen}
        >
          <ExtendCompetition
            setIsCompetitionModalOpen={setIsCompetitionModalOpen}
            extendThisIdComp={extendThisIdComp}
            setIsExtendCompSubmitting={setIsExtendCompSubmitting}
          />
        </Modal>
      )}
      <h3 className="border-t text-3xl text-center mt-4">All Competitions</h3>
      {allTournaments ? (
        <div className="grid grid-cols-3 gap-4">
          {allTournaments.map((item) => (
            <Competition
              item={item}
              refreshTournaments={refreshTournaments}
              setIsRegistrationModalOpen={setIsRegistrationModalOpen}
              setIsCompetitionModalOpen={setIsCompetitionModalOpen}
              setExtendThisIdComp={setExtendThisIdComp}
              setExtendThisIdReg={setExtendThisIdReg}
              setIsBuffering={setIsBuffering}
            />

          ))}
        </div>
      ) : (
        <div className="flex justify-center mt-10">
          <Spinner />
        </div>
      )}

      {allTournaments && allTournaments.length === 0 && (
        <div className="flex justify-center mt-10">
          <h3 className="text-center font-bold">No Tournaments</h3>
        </div>
      )}
    </div>
  );
};

export default AllCompetitions;