import { useFormik } from "formik";
import * as Yup from "yup";
import { luckyPhraseToInt } from "../../utils";

const RouletteOldSpin = ({mustSpin}) => {

  const formik = useFormik({
    initialValues: {
      selectedNum: "0",
      betAmount: "0",
      luckyPhrase: "",
    },
    validationSchema: Yup.object({
      selectedNum: Yup.number()
        .positive("The number must be positive.")
        .min(1, "The number must be greater than 1.")
        .required("Please select a number."),

      betAmount: Yup.number()
        .positive("The number must be positive.")
        .min(1, "The number must be greater than 1.")
        .required("Please select a bet amount."),
      luckyPhrase: Yup.string(),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      const { selectedNum, betAmount, luckyPhrase } = values;
      const int = luckyPhraseToInt(luckyPhrase);
      values.luckyPhrase = int;
      // setRequesting(true);
			console.log('Placing the old bet? with num',selectedNum, 'amount', betAmount, 'phrase', luckyPhrase)
    },
  });

  const spinEnabled =
    Number(formik.values.selectedNum) > 0 &&
    Number(formik.values.selectedNum) <= 32 &&
    Number(formik.values.betAmount) > 0;

  return (
      <form onSubmit={formik.handleSubmit}>
        <fieldset className="grid grid-cols-3 gap-4" disabled={mustSpin}>
          <div className="flex flex-col mb-5">
            <label
              htmlFor="selectedNum"
              className="mb-1 text-xs tracking-wide text-gray-600"
            >
              Selected Number
            </label>
            <div className="relative">
              {/* <div className="flex items-center justify-center absolute left-0 top-4 h-full w-10 text-gray-400">
              <FaDollarSign className="text-blue-500" />
            </div> */}
            </div>
            <input
              placeholder="Enter your selected number"
              type="number"
              name="selectedNum"
              onChange={formik.handleChange}
              className="text-sm placeholder-gray-500 pl-10 pr-4 rounded-2xl border border-gray-400 py-2 focus:outline-none focus:border-blue-400"
            />
            {formik.touched.selectedNum && formik.errors.selectedNum ? (
              <div className="text-xs text-red-500">
                {formik.errors.selectedNum}
              </div>
            ) : null}
          </div>
          <div className="flex flex-col mb-5">
            <label
              htmlFor="betAmount"
              className="mb-1 text-xs tracking-wide text-gray-600"
            >
              Bet Amount
            </label>
            <div className="relative">
              {/* <div className="flex items-center justify-center absolute left-0 top-4 h-full w-10 text-gray-400">
              <FaRegistered className="text-blue-500" />
            </div> */}
              <div className="flex items-center justify-center absolute right-0 top-4 h-full w-10 text-gray-400 cursor-pointer"></div>
            </div>
            <input
              placeholder="Enter your bet amount"
              type="number"
              name="betAmount"
              onChange={formik.handleChange}
              className="text-sm placeholder-gray-500 pl-10 pr-4 rounded-2xl border border-gray-400 py-2 focus:outline-none focus:border-blue-400"
            />
            {formik.touched.betAmount && formik.errors.betAmount ? (
              <div className="text-xs text-red-500">
                {formik.errors.betAmount}
              </div>
            ) : null}
          </div>

          <div className="flex flex-col mb-5">
            <label
              htmlFor="luckyPhrase"
              className="mb-1 text-xs tracking-wide text-gray-600"
            >
              Lucky Phrase (optional)
            </label>
            <input
              placeholder="Enter your lucky phrase"
              type="text"
              name="luckyPhrase"
              onChange={formik.handleChange}
              className="text-sm placeholder-gray-500 pl-10 pr-4 rounded-2xl border border-gray-400 py-2 focus:outline-none focus:border-blue-400"
            />
            {formik.touched.luckyPhrase && formik.errors.luckyPhrase ? (
              <div className="text-xs text-red-500">
                {formik.errors.luckyPhrase}
              </div>
            ) : null}
          </div>

          <div className="col-span-full">
            <button
              disabled={!spinEnabled}
              type="submit"
              className="w-full p-4 bg-cyan-800 rounded border-2 border-cyan-300 uppercase text-white text-center disabled:bg-cyan-200 disabled:text-white disabled:border-cyan-300 hover:text-cyan-800 hover:bg-white hover:border-cyan-800 "
            >
              SPIN
            </button>
          </div>
        </fieldset>
      </form>
  );
};

export default RouletteOldSpin;
