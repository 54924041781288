import { useCountdown } from "../hooks/useCountdown";

const CountDown = (props) => {
  const { targetDate, refresh } = props;

  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  const expired = days + hours + minutes + seconds <= 0;

  const hoursFormatted = hours < 10 ? `0${hours}` : hours;
  const minutesFormatted = minutes < 10 ? `0${minutes}` : minutes;
  const secondsFormatted = seconds < 10 ? `0${seconds}` : seconds;

  if (expired) {
    refresh();
    console.log("called this");
  }
  return (
    <>
      <span className="ml-1 mt-1 text-red-500 font-mono font-bold">
        {expired
          ? "Expired"
          : `${days}:${hoursFormatted}:${minutesFormatted}:${secondsFormatted}`}
      </span>
    </>
  );
};

export default CountDown;