import {
	FaRegistered,
	FaBolt,
	FaPlay,
	FaPen,
	FaTicketAlt,
} from "react-icons/fa";
import { useState, useEffect } from "react";
import Modal from "./Modal/Modal";
import {
	enableRegistration,
	endCompetition,
	extendCompetition,
	extendRegistration,
	getAllTournaments,
	getCompetitionWinners,
	registerPlayer,
	registerPlayerViaRelay,
	startCompetition,
} from "../utils";
import { useNavigate } from "react-router-dom";
import Countdown from "./Countdown";
import Spinner from "./Spinner";

import { useAccount } from "../context/AccountContext";
import { useContracts } from "../context/ContractsContext";

const Competition = ({item, refreshTournaments, setIsBuffering, setIsRegistrationModalOpen, setIsCompetitionModalOpen, setExtendThisIdComp, setExtendThisIdReg }) => {

	const navigate = useNavigate();

	const [allTournaments, setAllTournaments] = useState([]);
	const { accountAddress, isOrganizer } = useAccount();
	const { rouletteContract } = useContracts();

	return (
		<div
			onClick={() => {
				navigate(`/tournament/${item.tournamentId}`);
			}}
			className="cursor-pointer shadow-md p-4 shadow-gray-800 mt-4 flex flex-col"
		>
			{item.Id}
			<h3 className="text-center font-bold">{item.tournamentId}</h3>
			<div className="justify-center flex mt-4">
				<FaBolt className="text-3xl" />
			</div>
			<div className="mt-auto">
				<div className="mt-1">
					{isOrganizer && !item.registrationStart && (
						<button
							onClick={(e) => {
								e.stopPropagation();
								setIsBuffering(true);
								const competitionId = item.tournamentId;
								enableRegistration(rouletteContract, {
									competitionId,
								})
									.then(() => refreshTournaments())
									.catch((err) => {
										alert("Error enabling registration");
									})
									.finally(() => {
										setIsBuffering(false);
									});
							}}
							className="w-full text-center p-4 text-sm bg-blue-800 rounded-lg border-2 uppercase text-white cursor-pointer hover:text-cyan-800 hover:bg-white hover:border-cyan-800"
						>
							<FaTicketAlt className="h-full mx-auto" />
							Enable Registration
						</button>
					)}

					{item.registrationStart &&
						item.remainingRegistrationTime > 0 && (
							<div>
								<button
									onClick={(e) => {
										e.stopPropagation();
										setIsBuffering(true);
										const competitionId = item.tournamentId;
										registerPlayerViaRelay(rouletteContract, {
											competitionId,
										})
											.then((_) => refreshTournaments())
											.catch((err) => {
												alert("Error registering player");
											})
											.finally(() => {
												setIsBuffering(false);
											});
									}}
									className="w-full text-center p-4 text-sm bg-blue-800 rounded-lg border-2 uppercase text-white cursor-pointer hover:text-cyan-800 hover:bg-white hover:border-cyan-800"
								>
									<FaPen className="h-full mx-auto" />
									Register Now
								</button>
								<div className="flex text-sm">
									<h5 className="flex mx-auto mt-2">
										Registration Deadline:
										<Countdown
											refresh={refreshTournaments}
											targetDate={
												new Date(
													new Date().getTime() +
													item.remainingRegistrationTime * 1000
												)
											}
										/>
									</h5>
								</div>
							</div>
						)}

					{item.registrationStart &&
						item.remainingRegistrationTime <= 0 && (
							<div className="text-sm text-center text-red-600">
								Registration Closed
							</div>
						)}

					{item.competitionStart && item.remainingCompetitionTime > 0 && (
						<div>
							<button className="w-full text-center p-4 text-sm bg-green-800 rounded-lg border-2 uppercase text-white cursor-pointer hover:text-cyan-800 hover:bg-white hover:border-cyan-800">
								<FaPlay className="h-full mx-auto" />
								Play Now
							</button>
							<div className="flex text-sm">
								<h5 className="flex mx-auto mt-2">
									Competition Deadline:
									<Countdown
										targetDate={
											new Date(
												new Date().getTime() +
												item.remainingCompetitionTime * 1000
											)
										}
									/>
								</h5>
							</div>
						</div>
					)}
					{item.competitionStart &&
						item.remainingCompetitionTime <= 0 &&
						item.remainingRegistrationTime <= 0 && (
							<div className="text-sm text-center text-red-600">
								Competition Closed
							</div>
						)}
				</div>

				{isOrganizer &&
					((item.registrationStart &&
						item.remainingRegistrationTime > 0) ||
						(item.competitionStart &&
							item.remainingCompetitionTime <= 0)) && (
						<div className="mt-1">
							<button
								type="button"
								onClick={(e) => {
									setIsRegistrationModalOpen(true);
									setExtendThisIdReg(item.tournamentId);
									e.stopPropagation();
								}}
								class="w-full inline-block px-6 py-2 border-2 border-blue-600 text-blue-600 font-medium text-xs leading-normal uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
							>
								Extend Registration Time
							</button>
						</div>
					)}

				{isOrganizer &&
					item.competitionStart &&
					item.remainingCompetitionTime > 0 && (
						<div className="mt-1">
							<button
								type="button"
								onClick={(e) => {
									setIsCompetitionModalOpen(true);
									setExtendThisIdComp(item.tournamentId);
									e.stopPropagation();
								}}
								class="w-full inline-block px-6 py-2 border-2 border-blue-600 text-blue-600 font-medium text-xs leading-normal uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
							>
								Extend Competition Time
							</button>
						</div>
					)}

				{isOrganizer &&
					item.registrationStart &&
					!item.registrationOngoing &&
					!item.competitionStart && (
						<div className="mt-1">
							<button
								type="button"
								onClick={(e) => {
									e.stopPropagation();
									setIsBuffering(true);
									const competitionId = item.tournamentId;
									startCompetition(rouletteContract, {
										competitionId,
									})
										.then((res) => refreshTournaments())
										.catch((err) => {
											alert("Error starting competition");
										})
										.finally(() => {
											setIsBuffering(false);
										});
								}}
								class="w-full inline-block px-6 py-2 border-2 border-blue-600 text-blue-600 font-medium text-xs leading-normal uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
							>
								Start Competition
							</button>
						</div>
					)}

				{isOrganizer && item.competitionStart && !item.competitionOngoing && (
					<div className="mt-1">
						<button
							type="button"
							onClick={(e) => {
								setIsBuffering(true);
								const competitionId = item.tournamentId;
								endCompetition(rouletteContract, { competitionId })
									.then((res) =>
										getCompetitionWinners(rouletteContract, {
											competitionId,
										})
									)
									.then((winners) => alert(JSON.stringify(winners)))
									.catch((err) => {
										alert("Error ending competition");
									})
									.finally(() => {
										setIsBuffering(false);
									});
								e.stopPropagation();
							}}
							class="w-full inline-block px-6 py-2 border-2 border-blue-600 text-blue-600 font-medium text-xs leading-normal uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
						>
							End Competition
						</button>
					</div>
				)}
			</div>
		</div>

	);
};

export default Competition;