import { ethers } from 'ethers';
import React, { createContext, useContext, useState } from 'react';

const AccountContext = createContext()
const AccountUpdateContext = createContext()

export function useAccount() {
	return useContext(AccountContext)
}

export function useUpdateAccount() {
	return useContext(AccountUpdateContext)
}

export function AccountProvider({children}) {
	const [currentAccount, setCurrentAccount] = useState({accountAddress: ethers.constants.AddressZero, isConnected: false, isOrganizer: false})

	return (
		<AccountContext.Provider value={currentAccount}>
			<AccountUpdateContext.Provider value={setCurrentAccount}>
				{children}
			</AccountUpdateContext.Provider>
		</AccountContext.Provider>
	)
}