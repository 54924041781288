import { FaDollarSign, FaRegistered, FaArrowsAltH } from "react-icons/fa";
import { useState } from "react";

import { useFormik } from "formik";
import * as Yup from "yup";

import { useAccount } from "../context/AccountContext";
import { useContracts } from "../context/ContractsContext";

// Custom Imports
import Modal from "./Modal/Modal";
import { getAllTournaments, addCompetition } from "../utils";

export default (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { accountAddress, isOrganizer } = useAccount();
  const { rouletteContract } = useContracts();

  const { retrieveAllCompetitions } = props;

  const formik = useFormik({
    initialValues: {
      startBal: "",
      regDuration: "",
      compDuration: "",
    },
    validationSchema: Yup.object({
      startBal: Yup.number().required("Start Balance is required"),
      regDuration: Yup.string().required("Registration Deadline is required"),
      compDuration: Yup.number().required("Compeletion Duration is required"),
    }),

    onSubmit: (values, { setSubmitting }) => {
      console.log(values);
      console.log(isOrganizer);

      addCompetition(rouletteContract, values)
        .then((res) => {
          console.log(res);
          retrieveAllCompetitions();
        })
        .catch((err) => {
          alert("Error:try again");
        })
        .finally(() => {
          setSubmitting(false);
          setIsOpen(false);
        });
    },
  });

  const modalElement = (
    <div className=" flex flex-col bg-white shadow-md px-4 sm:px-6 md:px-8 lg:px-10 py-8 rounded-3xl w-50 max-w-md">
      <div className="font-medium self-center text-xl sm:text-3xl text-gray-800">
        Add Competition
      </div>
      <div className="mt-4 self-center text-xl sm:text-sm text-gray-800">
        Enter all of the details to add a competition
      </div>
      <div className="mt-10">
        <form onSubmit={formik.handleSubmit}>
          <div className="flex flex-col mb-5">
            <label
              htmlFor="startBal"
              className="mb-1 text-xs tracking-wide text-gray-600"
            >
              Starting Balance
            </label>
            <div className="relative">
              <div className="flex items-center justify-center absolute left-0 top-4 h-full w-10 text-gray-400">
                <FaDollarSign className="text-blue-500" />
              </div>
            </div>
            <input
              placeholder="Start balance amount in $"
              type="number"
              name="startBal"
              onChange={formik.handleChange}
              className="text-sm placeholder-gray-500 pl-10 pr-4 rounded-2xl border border-gray-400 py-2 focus:outline-none focus:border-blue-400"
            />
            {formik.touched.startBal && formik.errors.startBal ? (
              <div className="text-xs text-red-500">
                {formik.errors.startBal}
              </div>
            ) : null}
          </div>
          <div className="flex flex-col mb-5">
            <label
              htmlFor="regDuration"
              className="mb-1 text-xs tracking-wide text-gray-600"
            >
              Registration Deadline
            </label>
            <div className="relative">
              <div className="flex items-center justify-center absolute left-0 top-4 h-full w-10 text-gray-400">
                <FaRegistered className="text-blue-500" />
              </div>
              <div className="flex items-center justify-center absolute right-0 top-4 h-full w-10 text-gray-400 cursor-pointer"></div>
            </div>
            <input
              placeholder="Registration Deadline"
              type="number"
              name="regDuration"
              onChange={formik.handleChange}
              className="text-sm placeholder-gray-500 pl-10 pr-4 rounded-2xl border border-gray-400 py-2 focus:outline-none focus:border-blue-400"
            />
            {formik.touched.regDuration && formik.errors.regDuration ? (
              <div className="text-xs text-red-500">
                {formik.errors.regDuration}
              </div>
            ) : null}
          </div>

          <div className="flex flex-col mb-5">
            <label
              htmlFor="compDuration"
              className="mb-1 text-xs tracking-wide text-gray-600"
            >
              Competition Duration
            </label>
            <div className="relative">
              <div className="flex items-center justify-center absolute left-0 top-4 h-full w-10 text-gray-400">
                <FaArrowsAltH className="text-blue-500" />
              </div>
              <div
                // onClick={() => setViewPassword(!viewPassword)}
                className="flex items-center justify-center absolute right-0 top-4 h-full w-10 text-gray-400 cursor-pointer"
              ></div>
            </div>
            <input
              placeholder="Competition Duration"
              type="number"
              name="compDuration"
              onChange={formik.handleChange}
              className="text-sm placeholder-gray-500 pl-10 pr-4 rounded-2xl border border-gray-400 py-2 focus:outline-none focus:border-blue-400"
            />
            {formik.touched.compDuration && formik.errors.compDuration ? (
              <div className="text-xs text-red-500">
                {formik.errors.compDuration}
              </div>
            ) : null}
          </div>

          <div className="grid grid-cols-2 gap-4">
            <button
              type="button"
              onClick={getAllTournaments}
              className={`py-2 px-3 rounded-xl border-solid border-2 uppercase w-full border-red-600 hover:border-red-500 hover:bg-red-600 hover:text-white`}
            >
              Cancel
            </button>
            <button
              type="submit"
              className={`py-2 px-3 rounded-xl border-solid border-2 uppercase w-full border-indigo-600 hover:border-indigo-500 hover:bg-indigo-600 hover:text-white`}
            >
              GO!
            </button>
          </div>
        </form>
      </div>
    </div>
  );

  return (
    <>
      {isOrganizer && (
        <h3
          onClick={() => setIsOpen(true)}
          className="p-4 bg-cyan-800 rounded border-2 uppercase text-white text-center cursor-pointer hover:text-cyan-800 hover:bg-white hover:border-cyan-800"
        >
          Add a Competition
        </h3>
      )}

      {isOpen && (
        <Modal isSubmitting={formik.isSubmitting} setIsOpen={setIsOpen}>
          {" "}
          {modalElement}{" "}
        </Modal>
      )}
    </>
  );
};
