import { useState } from "react";
import {
  AllCompetitions,
  NewCompetition,
} from "../../components";

const Home = () => {
  const [refreshNow, setRefreshNow] = useState(false);

  const retrieveAllCompetitions = () => {
    setRefreshNow(true);
  };

  return (
    <>
      <div className="mt-8">
        <div className="">
          <div className="col-span-1 flex justify-center mx-auto items-center text-2xl">
            <div className="">
              <div className="grid grid-cols-1 gap-4">
                <NewCompetition
                  retrieveAllCompetitions={retrieveAllCompetitions}
                />
                <AllCompetitions
                  refreshNow={refreshNow}
                  setRefreshNow={setRefreshNow}
                />
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default Home;