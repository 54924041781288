import { useEffect, useState, useRef } from "react";
import { Wheel } from "react-custom-roulette";

import { useContracts } from "../../context/ContractsContext";

import { BigNumber } from "ethers";
import Spinner from "../Spinner";
import RouletteTable from "./RouletteTable";
import RouletteOldSpin from "./RouletteOldSpin";

// Generate a sequence of shuffled numbers
const gen_sequence = (upto) => {
  const BLACK_ELEMENTS = [
    2, 4, 6, 8, 11, 10, 13, 15, 17, 20, 22, 24, 26, 28, 29, 31, 33, 35,
  ];

  const numSequence = [];
  const data = []

  for (let i = 0; i <= upto; i++) {
    numSequence.push(i);
  }

  // numSequence.sort(() => Math.random() - 0.5);

  for (let i = 0; i < numSequence.length; i++) {
    let el = {};
    el.option = String(numSequence[i]);
    if (i === 0) {
      el.style = {
        backgroundColor: "#0f0",
        textColor: "#000",
      };
    } else if (BLACK_ELEMENTS.includes(i)) {
      el.style = {
        backgroundColor: "#000",
        textColor: "#fff",
      };
    } else {
      el.style = {
        backgroundColor: "#f00",
        textColor: "#000",
      };
    }
    data.push(el);
  }
  return data;
};

const data = gen_sequence(36);

const Roulette = () => {
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(null);
  // const [requesting, setRequesting] = useState(true);

  const [betNumbers, setBetNumbers] = useState([]);

  const [betPlaced, _setFullBetPlaced] = useState(false);
  const betPlacedRef = useRef(betPlaced);
  const setFullBetPlaced = (data) => {
    betPlacedRef.current = data;
    _setFullBetPlaced(data);
  };

  const [betId, _setBetId] = useState(false);
  const betIdRef = useRef(betPlaced);
  const setBetId = (data) => {
    betIdRef.current = data;
    _setBetId(data);
  };

  const { rouletteContract, vrfCoordinatorContract, randomNumContract } = useContracts();

  const url = new URL(window.location.href);
  const path = url.pathname;
  const tournamentId = path.split("/").at(-1);

  const handleBetResult = (
    _betId,
    _winningNum,
    payout
  ) => {
    // if (_playerAddr.toLowerCase() == accountAddress.toLowerCase()) {
    console.log(_betId, _winningNum)
    if (BigNumber.from(_betId).eq(BigNumber.from(betIdRef.current))) {
      const result = {
        betId: _betId.toString(),
        winningNum: _winningNum,
        payout: payout
      };
      console.log(result);
      setPrizeNumber(result.winningNum);
      setMustSpin(true);
      setFullBetPlaced(false);
    }
    // }
  };

  const handleBetPlaced = (
    betId,
    playerAddr,
    _userSeed
  ) => {
    if (betPlacedRef.current) {
      const bet = {
        betId: betId.toString(),
        playerAddr,
        userSeed: _userSeed,
      };
      setBetId(betId);
      console.log(bet);
    }
  };

  useEffect(() => {
    rouletteContract.on("BetResult", handleBetResult);
    rouletteContract.on("BetPlaced", handleBetPlaced);

    return () => {
      rouletteContract.off("BetResult", handleBetResult);
      rouletteContract.off("BetPlaced", handleBetPlaced);
    };
  }, []);

  return (
    <div className="text-center mx-auto relative">
      {betPlaced && (
        <div className="fixed top-0 left-0 w-full h-full bg-black z-10 bg-opacity-50">
          <div className="flex justify-center items-center h-full w-full">
            <Spinner />
          </div>
        </div>
      )}
      <div className="my-4 flex justify-center">
        <Wheel
          mustStartSpinning={mustSpin}
          // spinDuration={3}
          innerRadius={70}
          innerBorderColor="#ccc"
          innerBorderWidth={5}
          prizeNumber={prizeNumber}
          data={data}
          textDistance={85}
          fontSize={15}
          onStopSpinning={() => {
            setMustSpin(false);
            if (betNumbers.includes(prizeNumber)) {
              alert("Congratulations! YOU WIN");
            } else {
              alert("Sorry! TRY AGAIN");
            }
          }}
        />
      </div>
      <RouletteTable competitionId={tournamentId} setBetPlaced={setFullBetPlaced} setBetNumbers={setBetNumbers} />
      {/* <RouletteOldSpin mustSpin={mustSpin}/> */}

    </div>
  );
};

export default Roulette;