import React from "react";
import styles from "./Modal.module.css";
import { RiCloseLine } from "react-icons/ri";
import Spinner from "../Spinner";

const Modal = (props) => {
  // console.log(props);

  const { setIsOpen, children, isSubmitting } = props;

  return (
    <>
      <div
        className={styles.darkBG}
        onClick={() => {
          if (isSubmitting) {
            return;
          }
          setIsOpen(false);
        }}
      />
      <div className={styles.centered}>
        <div className="bg-white rounded-xl relative">
          {!isSubmitting && (
            <button
              className="absolute top-0 right-0 bg-white rounded shadow-md -mt-2 -mr-2"
              onClick={() => setIsOpen(false)}
            >
              <RiCloseLine />
            </button>
          )}
          {isSubmitting ? <Spinner /> : children}
        </div>
      </div>
    </>
  );
};

export default Modal;
