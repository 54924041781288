import { ethers } from 'ethers';
import React, { createContext, useContext } from 'react';

import RouletteGame from "../assets/RouletteGame.json";
import VRFCoordinatorMock from "../assets/VRFCoordinatorV2Mock.json";
import RandomNum from "../assets/Mock.json";
import contractAddresses from "../assets/contractAddresses.json";

const networks = {
  polygon: {
    chainId: `0x${Number(137).toString(16)}`,
    chainName: "Polygon Mainnet",
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18
    },
    rpcUrls: ["https://polygon-rpc.com/"],
    blockExplorerUrls: ["https://polygonscan.com/"]
  },
  mumbai: {
    chainId: `0x${Number(80001).toString(16)}`,
    chainName: "Mumbai Testnet",
    nativeCurrency: {
      name: "MATIC Test Token",
      symbol: "MATIC",
      decimals: 18
    },
    rpcUrls: ["https://rpc.ankr.com/polygon_mumbai"],
    blockExplorerUrls: ["https://mumbai.polygonscan.com/"]
  },
  hardhat: {
    chainId: `0x${Number(31337).toString(16)}`,
    chainName: "Hardhat Localhost",
    nativeCurrency: {
      name: "ETH Test Token",
      symbol: "ETH",
      decimals: 18
    },
    rpcUrls: ["http://localhost:8545/"],
    blockExplorerUrls: null
  },
	ropsten: {
		name: "Ropsten",
		rpcUrls: [
			"https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
			"wss://ropsten.infura.io/ws/v3/9aa3d95b3bc440fa88ea12eaa4456161"
		],
		nativeCurrency: {
			name: "Ropsten Ether",
			symbol: "ROP",
			decimals: 18
		},
		blockExplorers: [
			{
				name: "etherscan",
				url: "https://ropsten.etherscan.io",
				standard: "EIP3091"
			}
		]
	},
  rinkeby: {
    chainId: `0x${Number(4).toString(16)}`,
    chainName: "Rinkeby",
    title: "Ethereum Testnet Rinkeby",
    chain: "ETH",
    network: "testnet",
    rpcUrls: [
      `https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161`,
    ],
    nativeCurrency: {
      name: "Rinkeby Ether",
      symbol: "RIN",
      decimals: 18
    },
    blockExplorerUrls: [
      {
        "name": "etherscan-rinkeby",
        "url": "https://rinkeby.etherscan.io",
        "standard": "EIP3091"
      }
    ]
  },
};

let network = process.env.REACT_APP_NETWORK
console.log(network)

const ContractsContext = createContext()
const NetworksContext = createContext()

const rpcUrl = networks[network]['rpcUrls'][0]

const provider = new ethers.providers.JsonRpcProvider(rpcUrl)
console.log(provider)

network === 'hardhat' ? network = 'localhost' : console.log(`Network is: ${network}`)

const jsonAbi = RouletteGame.abi;
const rouletteContractAddress = contractAddresses[network]["roulette"];
const rouletteContract = new ethers.Contract(
  rouletteContractAddress,
  jsonAbi,
  provider
);
console.log(rouletteContract)

export function useContracts() {
  return useContext(ContractsContext)
}

export function useNetworks() {
  return useContext(NetworksContext)
}

export function ContractsProvider({ children }) {

  return (
    <NetworksContext.Provider value={{networks}}>
      <ContractsContext.Provider value={{ rouletteContract }}>
        {children}
      </ContractsContext.Provider>
    </NetworksContext.Provider>
  )
}