export const networkInfos = {
  polygon: {
    chainId: `0x${Number(137).toString(16)}`,
    chainName: "Polygon Mainnet",
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18
    },
    rpcUrls: ["https://polygon-rpc.com/"],
    blockExplorerUrls: ["https://polygonscan.com/"]
  },
  mumbai: {
    chainId: `0x${Number(80001).toString(16)}`,
    chainName: "Mumbai Testnet",
    nativeCurrency: {
      name: "MATIC Test Token",
      symbol: "MATIC",
      decimals: 18
    },
    rpcUrls: ["https://rpc.ankr.com/polygon_mumbai"],
    blockExplorerUrls: ["https://mumbai.polygonscan.com/"]
  },
  hardhat: {
    chainId: `0x${Number(31337).toString(16)}`,
    chainName: "Hardhat Localhost",
    nativeCurrency: {
      name: "ETH Test Token",
      symbol: "ETH",
      decimals: 18
    },
    rpcUrls: ["http://localhost:8545/"],
    blockExplorerUrls: null
  },
	ropsten: {
		name: "Ropsten",
		rpcUrls: [
			"https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
			"wss://ropsten.infura.io/ws/v3/9aa3d95b3bc440fa88ea12eaa4456161"
		],
		nativeCurrency: {
			name: "Ropsten Ether",
			symbol: "ROP",
			decimals: 18
		},
		blockExplorers: [
			{
				name: "etherscan",
				url: "https://ropsten.etherscan.io",
				standard: "EIP3091"
			}
		]
	},
  rinkeby: {
    chainId: `0x${Number(4).toString(16)}`,
    chainName: "Rinkeby",
    title: "Ethereum Testnet Rinkeby",
    chain: "ETH",
    networkName: "testnet",
    rpcUrls: [
      `https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161`,
    ],
    nativeCurrency: {
      name: "Rinkeby Ether",
      symbol: "RIN",
      decimals: 18
    },
    blockExplorerUrls: [
      {
        "name": "etherscan-rinkeby",
        "url": "https://rinkeby.etherscan.io",
        "standard": "EIP3091"
      }
    ]
  },
};


export const getNetwork = () => {
	let networkName = process.env.REACT_APP_NETWORK
	networkName === 'hardhat' ? networkName = 'localhost' : console.log(`Network is: ${networkName}`)

	const networkInfo = networkInfos[networkName]
  const rpcUrl = networkInfo.rpcUrls[0]

	return {
		networkName,
    rpcUrl,
		networkInfo
	}
}
