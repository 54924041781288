import "./index.css";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import { AccountProvider } from "./context/AccountContext";
import { ContractsProvider } from "./context/ContractsContext";

import routes from "./configs/routes";
import { TopNav } from "./components";

function App() {
  return (
    <ContractsProvider>
      <AccountProvider>
        <TopNav></TopNav>
        <Router>
          <Routes>
            {routes.map((route, key) => (
              <Route key={key} path={route.path} element={<route.component />} />
            ))}
            {/* <Route path="*" element={<NotFound />} /> */}
          </Routes>
        </Router>
      </AccountProvider>
    </ContractsProvider>
  );
}

export default App;
