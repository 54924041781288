import { ethers } from "ethers";
import { useEffect, useState, useRef } from "react";
import { useAccount, useUpdateAccount } from "../context/AccountContext";
import { useContracts } from "../context/ContractsContext"
import { fulfillRandomWords } from "../api";
import { checkIfOrganizer } from "../utils/utils";

const networkName = process.env.REACT_APP_NETWORK
console.log(networkName)

const networks = {
  polygon: {
    chainId: `0x${Number(137).toString(16)}`,
    chainName: "Polygon Mainnet",
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18
    },
    rpcUrls: ["https://polygon-rpc.com/"],
    blockExplorerUrls: ["https://polygonscan.com/"]
  },
  mumbai: {
    chainId: `0x${Number(80001).toString(16)}`,
    chainName: "Mumbai Testnet",
    nativeCurrency: {
      name: "MATIC Test Token",
      symbol: "MATIC",
      decimals: 18
    },
    rpcUrls: ["https://rpc.ankr.com/polygon_mumbai"],
    blockExplorerUrls: ["https://mumbai.polygonscan.com/"]
  },
  hardhat: {
    chainId: `0x${Number(31337).toString(16)}`,
    chainName: "Hardhat Localhost",
    nativeCurrency: {
      name: "ETH Test Token",
      symbol: "ETH",
      decimals: 18
    },
    rpcUrls: ["https://localhost:8545/"],
    blockExplorerUrls: null
  },
	ropsten: {
    chainId: `0x${Number(3).toString(16)}`,
		chainName: "Ropsten Testnet",
		nativeCurrency: {
			name: "Ropsten Ether",
			symbol: "ROP",
			decimals: 18
		},
		rpcUrls: [ "https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161" ],
		blockExplorerUrls: ["https://ropsten.etherscan.io"]
	},
  rinkeby: {
    chainId: `0x${Number(4).toString(16)}`,
    chainName: "Rinkeby",
    title: "Ethereum Testnet Rinkeby",
    chain: "ETH",
    network: "testnet",
    rpcUrls: [
      `https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161`,
    ],
    nativeCurrency: {
      name: "Rinkeby Ether",
      symbol: "RIN",
      decimals: 18
    },
    blockExplorerUrls: [
      {
        "name": "etherscan-rinkeby",
        "url": "https://rinkeby.etherscan.io",
        "standard": "EIP3091"
      }
    ]
  },
};


const TopNav = () => {
  // const [walletAddress, setWalletAddress] = useState('Connect')
  const [error, setError] = useState();
  const [currentAccount, setCurrentAccount] = useState(null)
  const [currentChainId, setCurrentChainId] = useState(null)

  const { vrfCoordinatorContract, randomNumContract, rouletteContract } = useContracts()

  const { isOrganizer } = useAccount()
  const updateAccount = useUpdateAccount()

  // const _lastRequestId = await randomNumContract.s_lastRequestId();
  // setLastRequestId(Number(_lastRequestId.toString()));

  const [lastRequestId, _setLastRequestId] = useState(0);
  const lastRequestIdRef = useRef(lastRequestId);
  const setLastRequestId = (data) => {
    lastRequestIdRef.current = data;
    _setLastRequestId(data);
  };


  function connectMetamask() {
    console.log('Trying to connect Metamask')
    window.ethereum
      .request({ method: 'eth_requestAccounts' })
      .then(handleAccountsChanged)
      .catch((err) => {
        if (err.code === 4001) {
          // EIP-1193 userRejectedRequest error
          // If this happens, the user rejected the connection request.
          console.log('Please connect to MetaMask.');
        } else {
          console.error(err);
        }
      });
  }

  function handleAccountsChanged(accounts) {
    console.log(`Trying to change account to: ${accounts[0]}`)
    if (accounts.length === 0) {
      // MetaMask is locked or the user has not connected any accounts
      console.log('Please connect to MetaMask.');
    } else if (accounts[0] !== currentAccount) {
      setCurrentAccount(accounts[0])
      checkAdmin(accounts[0])
      // Do any other work!
    }
  }

  // const checkLastRequestId = async () => {
  //   const lastRequestId = await randomNumContract.s_lastRequestId()
  //   setLastRequestId(lastRequestId)
  // }

  const changeNetwork = async ({ networkName }) => {
    try {
      if (!window.ethereum) throw new Error("No crypto wallet found");
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            ...networks[networkName]
          }
        ]
      });
      console.log(networks[networkName]['chainId'])
      return networks[networkName]['chainId']
    } catch (err) {
      console.log(err.message)
    }
  };

  const switchNetwork = async (networkName) => {
    setError();
    const chainId = await changeNetwork({ networkName, setError });
    setCurrentChainId(chainId)
    console.log(`Successfully switched to ${networkName}`)
  };

  const checkAdmin = async (currentAccount) => {
    console.log('checking if organizer')
    console.log(rouletteContract)
    const isOrganizer = await checkIfOrganizer(rouletteContract, currentAccount)
    console.log('organizer', isOrganizer)
    updateAccount({ accountAddress: currentAccount, isConnected: true, isOrganizer })
  }

  const connect = async () => {
    connectMetamask()
    // switchNetwork(networkName)
  }

  const handleNetworkChanged = (chainId) => {
    setCurrentChainId(chainId)
    console.log({ chainId });
  };

  useEffect(() => {
    connect()
    window.ethereum.on("chainChanged", handleNetworkChanged);
    window.ethereum.on("accountsChanged", handleAccountsChanged);

    // vrfCoordinatorContract.on("RandomWordsRequested", handleRandomWordsRequest)
    // checkLastRequestId()

    return () => {
      window.ethereum.removeListener("chainChanged", handleNetworkChanged);
      window.ethereum.removeListener("accountsChanged", handleAccountsChanged);
      // vrfCoordinatorContract.off("RandomWordsRequested", handleRandomWordsRequest)
    };
  }, []);


  // const getConnectedWallet = async () => {
  //   const signer = new ethers.providers.Web3Provider(window.ethereum).getSigner();
  //   const signerAddress = await signer.getAddress()
  //   setWalletAddress(signerAddress)
  // }

  return (
    <div className="text-white font-bold text-2xl">
      <nav className="bg-cyan-800">
        <div className="container mx-auto p-4">
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              Play Roulette to Earn Wearables
            </div>
            {
              !currentAccount && (
                <div className="flex items-center cursor-pointer hover:bg-cyan-900 rounded-sm p-2"
                  onClick={connect}>
                  Connect
                </div>
              )
            }
            {
              currentAccount && (
                <div className="flex-col cursor-pointer hover:bg-cyan-900 rounded-sm p-2 text-sm">
                  <div> {currentAccount} </div>
                  <div> Network ChainId: {currentChainId} </div>
                  <div> Organizer: {isOrganizer.toString().toUpperCase()} </div>
                </div>
              )
            }
          </div>
        </div>
      </nav>
    </div>
  );
};

export default TopNav;