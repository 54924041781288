import { useEffect, useState, useRef } from "react";
import { Roulette } from "../../components";
import { useNavigate } from "react-router-dom";
import { useAccount } from "../../context/AccountContext";

import {
  getTournamentFullDetails
} from "../../utils";

import { useContracts } from "../../context/ContractsContext";

const PlayView = () => {

  const [tournamentDetails, setTournamentDetails] = useState(null)
  const mounted = useRef(false);

  const { accountAddress, isConnected, isOrganizer } = useAccount()
  const {rouletteContract} = useContracts()

  const navigate = useNavigate();

  const checkTournament = async (tournamentId) => {
    const tournament = await getTournamentFullDetails(rouletteContract, {tournamentId})
    console.log(tournament)
    setTournamentDetails(tournament)
    if (tournament.startOutBalance == '0') {
      alert('Game does not exist')
      navigate('/')
    }
    else if (!tournament.registrationStart) {
      alert('Registration for the game has not started. Contact ADMIN to start registration')
      navigate('/')
    }
    else if (!tournament.competitionStart) {
      alert('Competition for the game has not started. Contact ADMIN to start competition.')
      navigate('/')
    }
    else {
      if (isConnected) {
        checkPlayerInTournament(tournament.players, accountAddress)
      }
    }
  }

  const checkPlayerInTournament = async (players, player) => {
    if (players.length > 0) {
      const playersList = players.map(({ playerAddress }) => playerAddress.toLowerCase())
      const playerIndex = playersList.indexOf(player)
      if (playersList.includes(player)) {
        console.log(`${player} has registered`)
        const playerDetails = players[playerIndex]

        if (playerDetails.balance == 0) {
          alert('Current account do not have sufficient balance to place any bet')
          navigate('/')
        }

      } else {
        alert('Current account is not registered for this competition')
        navigate('/')
      }
    }
    else {
      alert('This competition does not have players registered.')
      navigate('/')
    }

  }

  useEffect(() => {
    const url = new URL(window.location.href);
    const path = url.pathname;
    const tournamentId = path.split("/").at(-1);
    if (mounted.current) {
      checkTournament(tournamentId)
    }
    mounted.current = true;

    return () => { }
  }, [accountAddress])

  return (
    <>
      <div className="mt-8">
        <div className="grid grid-cols-1 gap-4">
          <Roulette />
          {/* <div className="col-span-1 text-center border-l-2 rounded">
            <h3 className="text-3xl w-full border-y-2 text-left pl-4">
              Instructions to play
            </h3>
            <ol className="text-xl text-left ml-8 m-4 hover:list-disc font-medium">
              <li>Select a number between 1 and 32 to place your bet.</li>
              <li>Select the bet amount.</li>
              <li>Click to spin the bet.</li>
            </ol>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default PlayView;