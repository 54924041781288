import {
	FaRegistered,
} from "react-icons/fa";
import { useState } from "react";
import { extendCompetition } from "../../utils";

import { useContracts } from "../../context/ContractsContext";

const ExtendCompetition = ({setIsCompetitionModalOpen, extendThisIdComp, setIsExtendCompSubmitting}) => {
	const [endTime, setEndTime] = useState(null);

	const { rouletteContract } = useContracts();

	return (
		<div className=" flex flex-col bg-white shadow-md px-4 sm:px-6 md:px-8 lg:px-10 py-8 rounded-3xl w-50 max-w-md">
			<div className="font-medium self-center text-xl sm:text-3xl text-gray-800">
				New Competition Deadline
			</div>
			<div className="mt-10">
				<form onSubmit={"formik.handleSubmit"}>
					<div className="flex flex-col mb-5">
						<label
							htmlFor="regDeadline"
							className="mb-1 text-xs tracking-wide text-gray-600"
						>
							New Competition Deadline
						</label>
						<div className="relative">
							<div className="flex items-center justify-center absolute left-0 top-4 h-full w-10 text-gray-400">
								<FaRegistered className="text-blue-500" />
							</div>
							<div className="flex items-center justify-center absolute right-0 top-4 h-full w-10 text-gray-400 cursor-pointer"></div>
						</div>
						<input
							onChange={(e) => setEndTime(new Date(e.target.value))}
							placeholder="Competition Deadline"
							type="datetime-local"
							name="comDeadline"
							className="text-sm placeholder-gray-500 pl-10 pr-4 rounded-2xl border border-gray-400 py-2 focus:outline-none focus:border-blue-400"
						/>
					</div>
					<div className="grid grid-cols-2 gap-4">
						<button
							type="button"
							onClick={() => {
								const newEndTime = Math.round(endTime.getTime() / 1000);
								if (endTime.getTime() < new Date().getTime()) {
									alert("New deadline must be in the future");
									return;
								}
								setIsExtendCompSubmitting(true);
								const competitionId = extendThisIdComp;
								extendCompetition(rouletteContract, {
									competitionId,
									newEndTime,
								})
									.then(() => {
										setIsCompetitionModalOpen(false);
									})
									.catch((err) => {
										alert("Error: Could not extend registration");
									})
									.finally(() => {
										setIsExtendCompSubmitting(false);
									});
							}}
							className={`py-2 px-3 rounded-xl border-solid border-2 uppercase w-full border-indigo-600 hover:border-indigo-500 hover:bg-indigo-600 hover:text-white`}
						>
							SAVE
						</button>
					</div>
				</form>
			</div>
		</div>
	);
}

export default ExtendCompetition;